 
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelPessoa extends PageBase { 
    service = new RelatorioService();
    item: any = {};
    overlay: boolean = false;
    filter:any = {}

    mounted() {
       this.filter = this.$route.query;
        this.overlay = true;  
        this.filter.empresaId = "1";
            this.service.ObterRelatatorioDocumentoPessoa(this.filter.pessoaId).then(
                res => {
                    this.item = res.data;
                }, 
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{this.overlay = false});
        } 
}
